<template>
  <div class="mainPage">
    <div class="loginBox">
      <!-- <p id="headTitle">SIKU RAJA</p>
      <p id="title">SISTEM KEUANGAN KURNIA JAYA</p> -->
      <img src="../assets/logo.png" id="logo" alt="">
      <form @submit.prevent="login">
        <!-- <p id="inputTitle">Username</p> -->
        <input type="text" required v-model="username" placeholder="Username">
        <!-- <p id="inputTitle">Password</p> -->
        <input type="password" v-model="password" required placeholder="Password">
        <b-button type="submit">LOGIN</b-button>
        <!-- <p id="copyright">Honesty and hard work are the two main pillars of character. - Mahatma Gandhi</p> -->
      </form>
    </div>
  </div>
</template>
    
    <script>
    import axios from "../API/axios"
    import Swal from "sweetalert2";
    import moment from "moment";
    export default {
    name: "LoginPage",
    data() {
      return {
        username: "",
        password: ""
      }
    },
    computed: {
      isLogin() {
        return this.$store.state.isLogin
      }
    },
    methods: {
      async login() {
        try {
          let data = await axios({
            method: 'post',
            url: "/user/login",
            data: {
              username: this.username,
              password: this.password,
            },
          });
          let date = moment(new Date()).format("YYYY-MM-DD");
          // console.log(data, "data");
          this.$store.commit("setIsLogin", true);
          localStorage.setItem('access_token', data.data.access_token);
          localStorage.setItem('role', data.data.role);
          localStorage.setItem('username', data.data.username);
          localStorage.setItem('date', date);
          Swal.fire("Semangat Pagi", "Semangat Pagi, Semangat Bekerja", "success");
          // this.$store.dispatch('getLocalStorage');
          this.$router.push("/voucer");
        } catch (err) {
          console.log(err);
          Swal.fire("Maaf", "Password/username anda salah", "error");
        }
      },
    }
    }
    </script>
    
    <style scoped>
    * {
      margin: 0;
    }
    .mainPage {
      display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url(../assets/1.jpeg);
  background-size: contain; /* Mengubah ke contain agar gambar mempertahankan aspeknya */
  background-repeat: repeat; /* Mengulang gambar jika tidak cukup besar */
    }
    .loginBox{
      background-color: #FFFFFF;
      border-radius:20px;
      padding-right: 40px;
      padding-left: 40px;
      padding-top: 32px;
      padding-bottom: 32px;
      border: 1px solid rgb(163, 162, 162);
      width: 400px;
    
    }
    #logo{
      width: 8rem;
      margin-bottom: 10px;
    }
    
    #title{
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 4px;
    }
    
    
    #subTitle{
      font-size: 16px;
      font-weight: normal;
      color: #757575;
      margin-bottom: 60px;
    }
    
    #inputTitle{
      text-align: left;
      color: #9E9E9E;
      font-size: 18px;
    }
    input{
    width: 100%;
    border-radius: 20px;
    border: 1px #E0E0E0 solid;
    padding: 4px 10px;
    margin-bottom: 20px;
    margin-top: 4px;
    }
    
    button{
     width: 100%;
     border-radius: 20px;
      background-color: #b1b3db;
    }
    
    button:hover{
      color: #00803C;
      background-color: #FFFFFF;
    }
    
    input:active input:hover{
      border-color: #00803C;
    }
    
    </style>