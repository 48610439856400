<template>
    <div class="Sidebar">
      <!-- kasir -->
      <!-- <p id="titleMenu">Transaksi</p> -->
      <router-link to="/voucer" >
        <div class="listMenu">
          <img src="../assets/dollar.png" alt="" />
          <p class="sidebarMenu">Kasir</p>
        </div>
      </router-link>
      <router-link to="/jurnal" >
        <div class="listMenu">
          <img src="../assets/transaction.png" alt="" />
          <p class="sidebarMenu">Jurnal</p>
        </div>
      </router-link>
      <!-- <router-link to="/addjurnal" >
        <div class="listMenu">
          <img src="../assets/money.png" alt="" />
          <p class="sidebarMenu">Jurnal Manual</p>
        </div>
      </router-link> -->
      <router-link to="/neraca" >
        <div class="listMenu">
          <img src="../assets/profit.png" alt="" />
          <p class="sidebarMenu">Laporan Keuangan</p>
        </div>
      </router-link>
      <router-link to="/bukubesar" >
        <div class="listMenu">
          <img src="../assets/journal.png" alt="" />
          <p class="sidebarMenu">Buku Besar</p>
        </div>
      </router-link>
      <router-link to="/account" >
        <div class="listMenu">
          <img src="../assets/profit.png" alt="" />
          <p class="sidebarMenu">Daftar Akun</p>
        </div>
      </router-link>
  
      <!-- Penagihan -->
    </div>
  </template>
  
  <script>
  export default {
    name: "Sidebar",
    computed: {
      role() {
        return localStorage.getItem("role");
      },
    },
  };
  </script>
  
  <style scoped>
  .Sidebar {
    padding-left: 5px;
    padding-top: 24px;
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: #f3f3f3;
    font-weight: 500;
    /* background-color: rgb(120, 39, 39); */
  }
  img {
    margin: 0 2px;
    max-width: 18px;
  }
  .sidebarMenu {
    color: black;
    text-decoration: none;
    margin-left: 0;
  }
  router-link a:hover {
    text-decoration: none;
  }
  p {
    font-size: 14px;
    margin: 0px;
    align-self: center;
  }
  #titleMenu {
    font-weight: 800;
    margin-top: 10px;
    align-self: flex-start;
    padding-left: 10px;
  }
  .Sidebar a:hover {
    text-decoration: none;
  }
  
  /* .Sidebar a:active {
    color: red;
  } */
  .listMenu {
    display: flex;
    margin-bottom: 14px;
  }
  
  .router-link-active {
    background-color: transparent;
    padding-left: 3px;
    font-weight: 800;
    border-radius:10px 0px 0px 10px ;
  }
  router-link a:active {
    text-decoration: none;
  }
  </style>
  