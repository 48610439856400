e<template>
  <div class="mainPage">
    <!-- {{ dataTable[0] }} -->
    <p id="headPage">Buku Besar</p>


    <div class="filterRow">
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Kelompok Mata Anggaran</p>
          <b-select v-model="KelompokMaId" @change="this.getMa">
            <option v-for="e in this.kelmas" :key="e.id" :value="e.id"> {{e.name}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Mata Anggaran </p>
          <b-select v-model="MataAnggaranId" @change="this.getSubMa">
            <option  v-for="e in this.mas" :key="e.id" :value="e.id"> {{e.name}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Sub Mata Anggaran</p>
          <b-select v-model="SubMataAnggaranId"  @change="this.getDataTable">
            <option v-for="e in this.submas" :key="e.id" :value="e.id"> {{e.name}} </option>
            <template slot="empty">Tidak ada hasil pencarian yang ditemukan.</template>
          </b-select>
        </div>
      </div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          
          <p id="inputTitle" >Tanggal Mulai</p>
          <b-input type="date"  v-model="startDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai</p>
          <b-input type="date" v-model="endDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input v-model="keyword" @change="getDataTable" ></b-input>
        </div>
        
      </div>
    </div>
  <div id="titleRow">
    <b-button id="addModal" @click.prevent="showAdd()">Tambah</b-button>
    <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
    <!-- <button @click.prevent="printCsv()" id="printer">
        <img src="../assets/printer.png" id="printer" alt="">
    </button> -->
  </div>
  <div class="pagination mt-2">
    <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
    <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
    <p class="mr-1"> {{this.currentPage}} </p>
    <p class="mr-1">/</p> 
    <p class="mr-1">{{this.totalPage}} </p> 
    <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
    <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
  </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>COA</th>
            <th>Kelompok Mata Anggaran</th>
            <th>Mata Anggaran</th>
            <th>Sub Mata Anggaran</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <!-- <td>{{ e }}</td> -->
            <td>{{ e.coa }}</td>
            <td>{{ e.KelompokMa.name }}</td>
            <td>{{ e.MataAnggaran.name }}</td>
            <td>{{ e.name }}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
    <div class="modal" id="mainModal">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Voucer</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">
          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Mata Anggaran</p>
              <b-select v-model="add.MataAnggaranId" required>
                <option v-for="e in mas" :key="e.id" :value="e.id">{{ e.name }} - {{ e.coa }}</option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Normal Balance</p>
              <b-select v-model="add.NormalBalanceId" required>
                <option value="1">Debet</option>
                <option value="2">Kredit</option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">COA</p>
              <b-input v-model="add.coa" type="text"  required></b-input>
            </div>
            <div class="inCol">
              <p id="titleModal">Nama</p>
              <b-input v-model="add.name" type="text"  required></b-input>
            </div>
          </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </form>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      dataTable: [],
      kelmas: [],
      mas: [],
      submas: [],
      currentPage: 1,
      perPage: 30,
			totalPage : 2,
      isLoading: false,
      isEdit: false,
      
      // filter
      BranchId: 0,
      KelompokMaId: 0,
      MataAnggaranId: 0,
      SubMataAnggaranId: 0,
      endDate: "",
      startDate: "",
      keyword: "",

      add : {
        MataAnggaranId: 0,
        NormalBalanceId: 1,
        coa: '',
        name: ''
      }

    }
  },
  created() {

    $('#mainModal').modal('hide')
    this.getDataTable()
    this.getKelMa()
  },
  computed: {
  },  
  methods: {

    resetFilter() {
      this.currentPage = 1
      this.KelompokMaId= 0
      this.MataAnggaranId= 0
      this.SubMataAnggaranId= 0
      this.endDate= ""
      this.startDate= ""
      this.keyword= ""
      this.getDataTable()
    },

    showAdd() {
      this.KelompokMaId = 0
      this.getMa()
      this.isEdit = false

      $('#mainModal').modal('show')
    },
    // data filter
    async getKelMa() {
      this.currentPage = 1
      try {
        let data = await axios({
          url: "/database/kelma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.kelmas = data.data
        
      } catch(err) {
        console.log(err)
      }
    },
    async getMa() {
      this.currentPage = 1
      try {
        let {data} = await axios({
          url: "/database/ma",
          params: {
            KelompokMaId : this.KelompokMaId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        console.log(data.length);
        this.mas = data
        this.getDataTable()
      } catch(err) {
        console.log(err);
      }
    },
    async getSubMa() {
      try {
        let {data} = await axios({
          url: "/database/subma",
          params: {
            KelompokMaId : this.KelompokMaId,
            MataAnggaranId: this.MataAnggaranId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.submas = data
        this.getDataTable()
      } catch(err) {
        console.log(err);
      }
    },


    async printCsv() {
      this.isLoading = true
      try {
        
        // if(this.startDate == "" && this.endDate == "" ) {
        //   throw {
        //     status: 500,
        //     msg: "Data Tidak Lengkap"
        //   }
        // }
        let {data} = await axios({
          method: "GET",
          url: "/jurnal/subjurnal/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            keyword: this.keyword,
            BranchId: this.BranchId,
            ProjectId: this.ProjectId,
            KelompokMaId: this.KelompokMaId,
            MataAnggaranId: this.MataAnggaranId,
            SubMataAnggaranId: this.SubMataAnggaranId,
          }
        })
        console.log(data);
        const dataToConvert = {
          data: data,
          filename: 'Buku Besar',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
        console.log(err);
      }

    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    formatUang(data) {
      // console.log(data);
			let uang = "";
			data = data.toString();
			for (let i = 0; i < data.length; i++) {
				if ((data.length - i) % 3 == 0 && i !== 0) {
				uang += `.${data[i]}`;
				} else {
				uang += data[i];
				}
			}
      return uang
    },
    async getDataTable() {
      this.isLoading = true
      try {
        let {data} = await axios({
          url: "/database/subma",
          params: {
            KelompokMaId : this.KelompokMaId,
            keyword : this.keyword,
            MataAnggaranId: this.MataAnggaranId,
            page: this.currentPage,
            limit: this.perPage,
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        console.log(data,"data");
        this.dataTable = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }
    },

    async addData() {
      this.isLoading = true
      try {
        let {data} = await axios({
          method: "post",
          url: "/database/subma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          data: this.add
        })
        this.getDataTable()
        this.isLoading = false
        $('#mainModal').modal('hide')
      } catch(err) {
        $('#mainModal').modal('hide')
        console.log(err);
        this.isLoading = false
      }
    },

    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>