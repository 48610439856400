import axios from 'axios';

// export default axios.create({
// 	baseURL: 'https://103.181.183.98:3001/',
// });
export default axios.create({
	baseURL: 'https://dbkaso.otnielpangkung.com/',
});
// export default axios.create({
// 	baseURL: 'http://localhost:3001',
// });
